import { useStoreSocket } from '@/store/store-socket';
import { useStoreGridApiExplorer } from '@/modules/marketplace/store/store-grid-api-explorer';
import { IRowNode } from 'ag-grid-enterprise';
import {
  BookOrder,
  QueryMarketplaceItem,
} from '@/connect/gen/modules/apiengine/services/venue/venue_pb';
import { JsonValue } from '@bufbuild/protobuf';
import { OmsOrderType, PbInstrumentInfo } from '@/connect';
import { throttle } from 'lodash';
import { onUnmounted } from 'vue';

export function useSocketExplorer(): void {
  const { getGridApi, expandAfterModelUpdated } = useStoreGridApiExplorer();

  const storeSocket = useStoreSocket();
  storeSocket.on('refreshVenueOrders', refreshVenueOrders);
  onUnmounted(() => storeSocket.off('refreshVenueOrders', refreshVenueOrders));

  function refreshVenueOrders(payload: JsonValue) {
    const gridApi = getGridApi();
    const incomingOrder = BookOrder.fromJson(payload);

    // we are not interested in "MARKET", avoid unecessary re-renders and fetching
    if (incomingOrder.orderType === OmsOrderType.MARKET) return;

    const [targetRow, orderKey] = findTargetRow(incomingOrder);

    // orders exists in grid and is active, "update" only the row, enabling flashing cells
    if (targetRow && orderKey && incomingOrder.active) {
      gridApi.applyServerSideTransaction({
        route: [incomingOrder.cusip],
        update: [{ ...targetRow, [orderKey]: incomingOrder }],
      });
    }
    // the FE doesn't know how to deal with sorting, or how to make "pairs" in the grid,
    // so we refresh the whole thing when "add" or "remove"
    else {
      gridApi.refreshServerSide({ route: [incomingOrder.cusip], purge: false });
      // also refresh top level row groups, they may have been added or removed
      refreshGroups();
    }
  }

  const refreshGroups = throttle(
    () => {
      expandAfterModelUpdated();
      getGridApi().refreshServerSide({ purge: false });
    },
    1000,
    {
      leading: true,
      trailing: true, // because we want the most recent data
    }
  );

  function findTargetRow(order: BookOrder) {
    const rows = getAllMarketplaceItemRows();
    const targetRow = rows.find((row) => findOrderKey(row, order));
    const orderKey = targetRow ? findOrderKey(targetRow, order) : null;
    return [targetRow, orderKey] as const;
  }

  // only return QueryMarketplaceItem, ignore PbInstrumentInfo (because they are row groups)
  function getAllMarketplaceItemRows() {
    const items: QueryMarketplaceItem[] = [];

    getGridApi().forEachNode((node: IRowNode<QueryMarketplaceItem | PbInstrumentInfo>) => {
      if (node.data instanceof QueryMarketplaceItem) {
        items.push(node.data);
      }
    });
    return items;
  }

  function findOrderKey(row: QueryMarketplaceItem, order: BookOrder) {
    if (row.lendOrder?.rootClientOrderRef === order.rootClientOrderRef) {
      return 'lendOrder';
    }
    if (row.borrowOrder?.rootClientOrderRef === order.rootClientOrderRef) {
      return 'borrowOrder';
    }
    return null;
  }
}
